import type { I18nOptions } from 'vue-i18n';

export const i18nConfig: I18nOptions = {
  legacy: false,
  locale: 'es-MX',
  numberFormats: {
    'es-MX': {
      currency: {
        style: 'currency',
        currency: 'MXN',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      },
      percent: {
        style: 'percent',
        useGrouping: false,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      },
    },
  },
};

export default defineI18nConfig(() => i18nConfig);
